import React from 'react';
import { graphql } from 'gatsby';
import Service from './Service';

export default function NewsPage(props) {
  const { data = {} } = props;
  const { name, hero, content, verticalCards } = data.newspage || {};

  return (
    <Service
      name={name}
      hero={hero}
      content={content}
      verticalCards={verticalCards}
    />
  );
}

export const query = graphql`
  query($slug: String!) {
    # renames query
    newspage: sanityNewspage(slug: { current: { eq: $slug } }) {
      name
      hero {
        ... on SanityHeroWithText {
          name
          description
          image {
            asset {
              url
            }
          }
        }
        ... on SanityAccessibleImage {
          image {
            asset {
              url
            }
          }
          alt
        }
      }
      content {
        name
        _rawContent(resolveReferences: { maxDepth: 10 })
        link {
          link
          url
        }
        link2 {
          url
          link
        }
      }
      verticalCards {
        id
        name
        description
        link
        image {
          image {
            asset {
              fluid(maxHeight: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
        }
      }
    }
  }
`;
