import React from 'react';
import AccessibleImage from '../blocks/AccessibleImage';
import HeroImageWithText from '../blocks/HeroImageWithText';

function RenderHeroOrHeading({ hero, name }) {
  if (hero?.length > 0) {
    if (hero[0].name) {
      // render the heroWithText if name exists
      return (
        <div className="hero hero--top">
          <HeroImageWithText hero={hero[0]} />
        </div>
      );
    }
    return (
      // otherwise, render accessibleImage
      <div className="hero hero--top">
        <AccessibleImage image={hero[0]} />
      </div>
    );
  }
  if (name) {
    return <h1>{name}</h1>;
  }
}

export default function HeroOrHeading({ hero, name }) {
  return <>{hero && <RenderHeroOrHeading hero={hero} name={name} />}</>;
}
