import React from 'react';
import styled from 'styled-components';

const HeroImageWithTextStyles = styled.section`
  background-image: url(${(props) => props.url});
  background-position: center right;
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
  height: 100%;
  width: calc(100vw - 6px); // accommodate scrollbar
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: initial;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  .content {
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(44, 94, 156, 0.8);
    color: white;
    padding: 1rem;

    @media (min-width: 768px) {
      width: 70%;
      margin-left: 3rem;
    }

    @media (min-width: 967px) {
      width: 40%;
      margin-left: 5rem;
    }

    h1 {
      color: white;
      font-size: 2.55rem;
    }
  }
`;

export default function HeroImageWithText({ hero }) {
  const { name, description, image } = hero || {};
  return (
    <HeroImageWithTextStyles url={image.asset.url}>
      <div className="content">
        {name && <h1>{name}</h1>}
        {description && <p>{description}</p>}
      </div>
    </HeroImageWithTextStyles>
  );
}
