import React from 'react';
import HeroOrHeading from './HeroOrHeading';
import TextSection from '../blocks/TextSection';
import CardList from '../blocks/CardList';
import SEO from './SEO';

export default function Service({ name, hero, content, verticalCards }) {
  return (
    <>
      <SEO title={name} />
      {hero && <HeroOrHeading hero={hero} name={name} />}
      {content && <TextSection content={content} />}
      {verticalCards?.length > 0 && <CardList cards={verticalCards} />}
    </>
  );
}
