import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

const CardGroupStyles = styled.section`
  @media (min-width: 768px) {
    display: flex;
    flex-flow: row;
  }
`;

const CardStyles = styled.article`
  background: var(--green);
  margin-bottom: 1rem;
  width: 100%;
  min-height: 25vh;
  display: flex;
  flex-flow: column;
  position: relative;
  &.is-linked {
    &:hover {
      background: #0b4e28;
    }
  }

  &:nth-of-type(2) {
    background: var(--orange);
    &.is-linked {
      &:hover {
        background: #ad5d23;
      }
    }
  }

  &:nth-of-type(3) {
    background: var(--teal);
    &.is-linked {
      &:hover {
        background: #247574;
      }
    }
  }

  &:nth-of-type(4) {
    background: var(--violet);
    &.is-linked {
      &:hover {
        background: #2d3073;
      }
    }
  }

  a {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &:focus {
      outline: 0 !important;
      
      &::before {
        outline: 4px solid var(--violet);
        outline-offset: 4px;
      }
    }
  }

  img {
    width: 100%;
    order: 1;
  }

  .content {
    padding: 2rem;
    order: 2;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  h2,
  p,
  a {
    color: var(--white);
  }

  p {
    font-size: 1.25rem;
  }
`;

function IsLinkedCard({ link, name }) {
  if (link) {
    return (
      <h2>
        <a href={link}>{name}</a>
      </h2>
    );
  }
  return <h2>{name}</h2>;
}

function SingleCard({ card }) {
  const { name, description, link, image } = card || {};
  return (
    <CardStyles className={link && 'is-linked'}>
      <div className="content">
        {<IsLinkedCard link={link} name={name} />}
        {description && <p>{description}</p>}
      </div>
      {image && <Img fluid={image.image.asset.fluid} alt={image.alt} />}
    </CardStyles>
  );
}

export default function CardList({ cards }) {
  return (
    //  Up to 4 (max)
    // use either all images, or none
    // accepts for group: up to 4 cards., or none.
    // accepts, for each card: heading, optional image, link, and description
    <CardGroupStyles>
      {cards.map((card) => (
        <SingleCard key={card.id} card={card} />
      ))}
    </CardGroupStyles>
  );
}
